



import axios from "axios";
import {message} from "ant-design-vue";
import store from "@/store";



const eicService = axios.create({

    // baseURL: 'https://eic.chipsresale.com', // 所有的请求地址前缀部分
    // baseURL : 'http://127.0.0.1:9666', // 所有的请求地址前缀部分
    baseURL : process.env.VUE_APP_EIC_SERVER_URL,

    timeout : 1000 * 60 * 60, // 请求超时时间毫秒
    withCredentials : true, // 异步请求携带cookie

    headers : {
        'Content-Type' : 'application/json',
        'jwt' : store.state.jwt,
    }
});


eicService.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

eicService.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            if( response.toString().indexOf("签名") === 0 ){
                message.error(response.toString());
            }
        }
    },
    error => {
        //console.log(error.t)
        if( error.toString().toLowerCase().indexOf("network") === -1 ){
            message.error(error.toString());
        }
    }
);


const newApiService = axios.create({


    baseURL : process.env.VUE_APP_NEW_API_URL,


    timeout : 1000 * 60 * 60, // 请求超时时间毫秒
    withCredentials : true, // 异步请求携带cookie

    headers : {
        'Content-Type' : 'application/json',
        'jwtAdmin' : store.state.jwt
    }
});


const dailiaoService = axios.create({

    // baseURL: 'https://api.chipsresale.com', // 所有的请求地址前缀部分
    // baseURL : 'http://127.0.0.1:5661', // 所有的请求地址前缀部分
    baseURL : process.env.VUE_APP_DAILIAO_SERVER_URL,

    timeout : 1000 * 60 * 60, // 请求超时时间毫秒
    withCredentials : true, // 异步请求携带cookie

    headers : {
        'Content-Type' : 'application/json',
        'jwtAdmin' : store.state.jwt
    }
});

const dailiaoUpService = axios.create({

    // baseURL: 'https://api.chipsresale.com', // 所有的请求地址前缀部分
    // baseURL : 'http://127.0.0.1:5661', // 所有的请求地址前缀部分
    baseURL : process.env.VUE_APP_DAILIAO_SERVER_URL,

    timeout : 1000 * 60 * 60, // 请求超时时间毫秒
    withCredentials : true, // 异步请求携带cookie
    headers : {
        'Content-Type' : 'multipart/form-data',
        'jwtAdmin' : store.state.jwt
    }
});


dailiaoService.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

dailiaoService.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            message.error(response.toString());
        }
    },
    error => {
        //console.log(error.t)
        if( error.toString().toLowerCase().indexOf("network") === -1 ){
            message.error(error.toString());
        }
    }
);


const dailiaoDownloadService = axios.create({

    // baseURL: 'https://api.chipsresale.com', // 所有的请求地址前缀部分
    // baseURL : 'http://127.0.0.1:5661', // 所有的请求地址前缀部分
    baseURL : process.env.VUE_APP_DAILIAO_SERVER_URL,

    timeout : 1000 * 60 * 60, // 请求超时时间毫秒
    withCredentials : true, // 异步请求携带cookie

    headers : {
        'Content-Type' : 'application/json',
        'jwtAdmin' : store.state.jwt,
    },
    responseType : "blob"
});


dailiaoDownloadService.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

dailiaoDownloadService.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            message.error(response.toString());
        }
    },
    error => {
        //console.log(error.t)
        if( error.toString().toLowerCase().indexOf("network") === -1 ){
            message.error(error.toString());
        }
    }
);









export default {

    postEIC(url, data) {

        return new Promise((resolve, reject) => {
            eicService({
                url : url,
                method : 'POST',
                data : JSON.stringify(data)
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.log(JSON.stringify(data))
                    console.log(res.message)
                    reject(err)
                });
        })
    },


    postFile(url, data) {


        return new Promise((resolve, reject) => {
            dailiaoUpService({
                url : url,
                method : 'POST',
                data : data
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },

    newApi(url, data) {

        return new Promise((resolve, reject) => {
            newApiService({
                url : url,
                method : 'POST',
                data : JSON.stringify(data)
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },


    post(url, data) {


        return new Promise((resolve, reject) => {
            dailiaoService({
                url : url,
                method : 'POST',
                data : JSON.stringify(data)
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        })
    },

    postDownload(url, data) {

        return new Promise((resolve, reject) => {
            dailiaoDownloadService({
                url : url,
                method : 'POST',
                timeout: 1000 * 60 * 20, // 20分钟
                data : JSON.stringify(data)
            })
                .then(res => {

                    const { data, headers } = res

                    // console.log(headers)

                    let fileName = "下载文件的文件"
                    let hFile = headers['content-disposition'];
                    if( hFile !== undefined ){
                        fileName = decodeURI( hFile.split("=")[1] )
                    }
                    // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
                    //const blob = new Blob([JSON.stringify(data)], ...)
                    const blob = new Blob([ data ], { type : headers['content-type'] })
                    let dom = document.createElement('a')
                    let url = window.URL.createObjectURL(blob)
                    dom.href = url
                    // alert(fileName);
                    dom.download = fileName
                    dom.style.display = 'none'
                    document.body.appendChild(dom)
                    dom.click()
                    dom.parentNode.removeChild(dom)
                    window.URL.revokeObjectURL(url)

                    resolve("ok");

                })
                .catch(err => {
                    reject(err)
                });
        })
    }

};